import React from "react";
import { Column, Row } from "@cortexglobal/rla-components";
// import CanAccessLeague from "../routing/CanAccessLeague";
import RedeemAmazonImg from "../../img/homepage-cards/redeem-amazon.png";
// import ChampionsLeagueImg from "../../img/cards/technicians-champions-league.png";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
// import GameImg from "../../img/games-button.png";
import GameImg from "../../img/PS5-prize-image.jpeg";
// import CompetitionsImg from "../../img/bmw_competitions_img1.png";
import ThisOrThatImg from "../../img/this-or-that-button.jpg";
import {
    Card,
    CardLabel,
    CardActionWrapper,
    CardInfoButton,
    CardLinkButton,
    CardLink,
} from "./cardComponents";
// import MonthlyCompetitionCard from "./MonthlyCompetitionCard";

const HomepageCards = ({
    userCanViewRewards,
    extend,
    points,
    score,
    position,
    competition,
}) => {
    const CompetitionImg = competition?.data?.thumbnail;
    return (
        <Row style={{ marginTop: "20px" }}>
            {userCanViewRewards && (
                <Column xlarge={4}>
                    <CardLabel>
                        <SectionTitle
                            title="Redeem"
                            subtitle="Redeem your available rewards"
                        />
                    </CardLabel>

                    <Card
                        style={{
                            backgroundImage: `url(${RedeemAmazonImg})`,
                            animationDelay: "0ms",
                        }}
                        className="slit-in-vertical"
                    >
                        <CardLink to="redeem">
                            <p>Redeem</p>
                        </CardLink>
                    </Card>

                    {extend && (
                        <CardActionWrapper>
                            <CardLinkButton to="redeem">Redeem</CardLinkButton>
                            <CardInfoButton>
                                <span>
                                    My Rewards:{" "}
                                    <strong>£{points.stats.balance}</strong>
                                </span>
                            </CardInfoButton>
                        </CardActionWrapper>
                    )}
                </Column>
            )}
            <Column xlarge={4}>
                <CardLabel>
                    <SectionTitle
                        title="Take the weekly quiz"
                        subtitle="Have you got what it takes to win this months prize?"
                    />
                </CardLabel>
                <Card
                    className="slit-in-vertical"
                    style={{
                        backgroundImage: `url(${CompetitionImg || GameImg})`,
                        animationDelay: "250ms",
                    }}
                >
                    <CardLink to="/games/weekly-quiz">
                        <p>Monthly Prize</p>
                    </CardLink>
                </Card>
                {extend && (
                    <CardActionWrapper>
                        <CardLinkButton to="/games/weekly-quiz">
                            Take the quiz
                        </CardLinkButton>
                        <CardInfoButton className="white">
                            <span>
                                Overall Score: <strong>{score}%</strong>
                            </span>
                        </CardInfoButton>
                    </CardActionWrapper>
                )}
            </Column>
            <Column xlarge={4}>
                <CardLabel>
                    <SectionTitle
                        title="Play This or That"
                        subtitle="It's simple, how many points can you get?"
                    />
                </CardLabel>
                <Card
                    className="slit-in-vertical"
                    style={{
                        backgroundImage: `url(${ThisOrThatImg})`,
                        animationDelay: "250ms",
                    }}
                >
                    <CardLink to="/games/this-or-that">
                        <p>Play This or That</p>
                    </CardLink>
                </Card>
                {extend && (
                    <CardActionWrapper>
                        <CardLinkButton to="/games/this-or-that">
                            Play now
                        </CardLinkButton>
                        <CardInfoButton className="white">
                            <span>
                                My Position: <strong>{position}</strong>
                            </span>
                        </CardInfoButton>
                    </CardActionWrapper>
                )}
            </Column>
        </Row>
    );
};

export default HomepageCards;
