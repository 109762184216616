import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";

import UserSummary from "../../components/global/UserSummary";
import { SectionTitle } from "@cortexglobal/bmw-shared-components";
import { CardLabel } from "../../components/infoCards/cardComponents";
import AppContext from "../../components/global/AppContext";
import NewsListing from "./NewsListing";

const RowHeading = styled(Row)`
    padding-top: 20px;
    padding-bottom: 20px;

    hr {
        margin: 10px 0 0;
        background-color: rgb(70 70 70 / 0.32);
    }

    @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.large}px) {
        hr {
            display: none;
        }

        p {
            font-size: 1rem;
        }
    }
`;

class NewsPage extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {({ user, mobileView }) => {
                    return (
                        <div className="page page-news">
                            <UserSummary />
                            <RowHeading>
                                <Column small={12}>
                                    <CardLabel>
                                        <SectionTitle
                                            title="News"
                                            subtitle="Keep up with the latest on BMW Elite"
                                        />
                                        <hr />
                                    </CardLabel>
                                </Column>
                            </RowHeading>
                            <NewsListing />
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state.auth;
    return {
        user,
    };
};

export default connect(mapStateToProps)(NewsPage);
